#icon,
#social-media-icon {
  color: #ffc43e;
  padding-right: 5px;
  font-size: 18px;
  font-weight: 400;
  align-self: center;
}

.top-header-container {
  background-color: #ffc43e;
  width: 100%;
  height: auto;
  min-height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .menu-area {
    .menu {
      font-family: 'Trebuchet MS';
      text-transform: capitalize;
      font-size: 12px;
      font-weight: 400;
      padding: 20px 15px;
      cursor: pointer;
      text-decoration: none;
      color: #4b5662;
      position: relative;

      &:hover {
        color: #ffffff;
      }
    }
  }
}

.header-top-area {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #ffc43e;
  height: 60px;
  padding: 0 10px 0 10px;
  margin-top: 10px;
  font-family: 'Open Sans', sans-serif;
  color: #666666;

  .collapsible-menu-icon {
    display: none;
  }

  .logo {
    width: 70%;

    img {
      max-width: 150px;
      height: auto;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .menu-area {
    width: 40%;
    display: flex;
    margin-right: 2vw;
  }

  .menu {
    font-family: 'Trebuchet MS';
    font-size: 15px;
    font-weight: 600;
    text-transform: capitalize;
    padding: 20px 15px;
    cursor: pointer;
    text-decoration: none;
    color: #4b5662;
    position: relative;

    &:hover {
      color: #ffc43e;
    }
  }

  .active-menu {
    color: #ffc43e;
  }

  .user-area {
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 13px;

    .login-button,
    .sign-up-button,
    .logout-button {
      padding: 0 12px;
      cursor: pointer;
      font-family: serif;
    }

    .login-button {
      border-right: 1px solid #d3d3d3;
    }

    .profile-icon {
      width: 24px;
      height: 24px;
      border-radius: 12px;
      margin-right: 10px;
      cursor: pointer;
    }

    .customized-menu {
      margin-top: 10px;
    }

    .cart {
      padding-left: 10px;
      cursor: pointer;
      border-left: 1px solid #d3d3d3;

      .cart-icon {
        align-self: center;
      }
    }
  }

  @media only screen and (max-width: 800px) {
    padding: 0px;
    .logo {
      width: 80%;
    }
    .menu {
      font-size: 15px;
      font-weight: 600;
      font-family: 'Trebuchet MS';
      text-transform: capitalize;
      padding: 12px;
      cursor: pointer;
    }

    .collapsed {
      margin: 20px 0;
    }
  }
  @media only screen and (max-width: 1053px) {
    height: auto;
    min-height: 50px;
    position: relative;

    .collapsible-menu-icon {
      display: block;
      margin: 0 10px 0 10px;
      cursor: pointer;
    }

    .logo {
      width: 80%;
      display: block;
      margin: 0px;
      margin-left: -5px;

      a {
        padding: 20px 0px;
      }
    }

    .menu-area {
      display: none;
    }

    .menu {
      font-size: 15px;
      font-weight: 600;
      font-family: 'Trebuchet MS';
      text-transform: capitalize;
      padding: 12px;
      cursor: pointer;
    }

    .user-area {
      top: 10px;
      right: 0;
    }

    .layout-container {
      .content-wrap {
        margin-top: -30px;
      }
    }
  }
}

.menu-drawer {
  .menu-container {
    width: 250px;

    .logo {
      width: 100%;
      .navbar-brand {
        align-items: center;
        justify-content: center;
        display: flex;
        img {
          width: 40%;
          height: auto;
        }
      }
    }

    ul > div {
      .menu {
        font-size: 13px;
        font-weight: 400;
        text-transform: uppercase;
        padding: 20px 15px;
        cursor: pointer;
        text-decoration: none;
        color: #4b5662;
        position: relative;

        &:hover {
          color: palevioletred;
        }
      }
    }
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 280px) and (max-width: 480px) {
  .top-header-container {
    .menu-area {
      display: flex;
      justify-content: space-around;
      flex: 1;

      .menu {
        padding: 0;
      }
    }
  }
}

@media (min-width: 1050px) and (max-width: 1400px) {
  .header-top-area {
    .menu-area {
      width: 45%;
    }
  }
}

.fixed-header {
  position: fixed;
  padding: 10px 0;
  width: 100%;
  top: 0%;
  background-color: floralwhite;
  z-index: 100;
  margin-top: 0;

  animation-name: slideDown;
  -webkit-animation-name: slideDown;

  animation-duration: 1s;
  -webkit-animation-duration: 1s;

  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@-webkit-keyframes slideDown {
  0% {
    -webkit-transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0%);
  }
}
