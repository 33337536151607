.layout-container {
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;

  .content-wrap {
    padding-bottom: 25rem; /* Footer height */
    background-color: #f7f8fa;
    display: flex;
    justify-content: center;
    min-height: 100vh;
  }
}

@media (max-width: 375px) {
  .layout-container {
    .content-wrap {
      padding-bottom: 46rem;
    }
  }
}
