// fonts
$fonts: (
  'heading': 'heading-font',
  'content': 'Open Sans',
  'fallBackContent': sans-serif,
);

$font-colors: (
  'dark-gray': #55595d,
  'gray': #686868,
  'light-gray': #919191,
  'white': #ffffff,
  'alto': #d3d3d3,
  'blue-bayoux': #525c7d,
  'william': #406775,
  'bombay': #afb4bf,
  'ebony-clay': #282c3f,
  'black': #333333,
  'pomegranate': #eb370e,
);

/* Colors */
$base-colors: (
  'primary-color': #ffc43e,
  'primary-light-color': floralwhite,
  'text-color': #e91e63,
);
