@import './variable.scss';

$primary-color: map_get($base-colors, 'primary-color');
$primary-light-color: map_get($base-colors, 'primary-light-color');
$white: map_get($font-colors, 'white');
$black: map_get($font-colors, 'black');
$dark-gray: map_get($font-colors, 'dark-gray');
$pomegranate: map_get($font-colors, 'pomegranate');

.main-footer-area {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 23rem; /* Footer height */
  z-index: 1;
  background-color: $primary-light-color;
  font-family: 'Trebuchet MS';

  .footer-grid-container {
    width: 80%;
    margin: 0 auto;

    .contact-info {
      .logo {
        max-width: 60%;
        height: auto;
      }

      .initials {
        color: $primary-color;
      }

      h4 {
        color: $black;
        font-size: 16px;
      }

      span {
        color: $black;
        font-size: 14px;
        display: block;
        margin-bottom: 10px;
        word-break: break-all;
      }
    }

    .useful-links {
      h5 {
        font-size: 18px;
        color: $black;
        margin-bottom: 25px;
        display: block;
        text-transform: capitalize;
      }

      ul {
        position: relative;
        z-index: 1;
        margin: 0;
        padding: 0;

        li {
          list-style: none;
          display: flex;

          a {
            display: block;
            margin-bottom: 10px;
            color: $black;
            font-size: 14px;
            font-weight: 400;
            text-transform: capitalize;
          }
        }
      }
    }

    .social-media {
      h5 {
        font-size: 18px;
        color: $black;
        margin-bottom: 25px;
        display: block;
        text-transform: capitalize;
      }

      .social-info {
        padding-top: 18px;
        display: flex;
        flex-direction: row;
        align-items: center;

        a {
          text-decoration: none;
        }

        #footer-social-media-icon {
          font-size: 30px;
          padding-right: 10px;
        }

        .facebook-icon {
          color: #3b5998;
        }

        .instagram-icon {
          color: #e4405f;
        }

        .twitter-icon {
          color: #00acee;
        }
      }
    }
  }

  .copywrite-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #273d4b;

    p {
      font-size: 14px;
      color: $dark-gray;
      margin-bottom: 0;
      line-height: 1.7;
      padding-top: 5px;

      #icon {
        color: $pomegranate;
        vertical-align: middle;
      }

      a {
        color: $dark-gray;
        font-size: 14px;
        text-decoration: none;

        &:hover {
          color: $pomegranate;
          transition-duration: 500ms;
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .main-footer-area {
    height: 45rem;

    .footer-grid-container {
      width: 80%;
      margin: 0 auto;

      .contact-info {
        .logo {
          width: 100%;
          max-width: 100%;
          height: auto;
        }

        h4 {
          font-size: 14px;
        }
      }
    }
  }
}
