$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669f2;

.modal {
  .paper {
    .modal-content {
      background-color: transparent;
    }
  }
}
.login-page {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 75%;
  display: flex;
  justify-content: center;

  .login-content-container {
    padding: 20px 40px;
    width: 40%;
    height: 80%;

    .close-icon {
      float: right;

      &:hover {
        color: #ffc43e;
        font-weight: bold;
        cursor: pointer;
      }
    }

    .title {
      display: block;
      font-family: 'Trebuchet MS';
      font-size: 39px;
      color: #333333;
      line-height: 1.2;
      text-align: center;
    }

    .label {
      font-size: 16px;
    }

    label + .MuiInput-formControl {
      margin-top: 22px;
      margin-bottom: 20px;
    }

    .MuiInput-underline:before {
      bottom: -8px;
    }

    .MuiInput-underline:after {
      bottom: -8px;
    }

    .icon {
      color: gray;
    }

    .separator {
      display: flex;
      align-items: center;
      justify-content: center;
      color: gray;
    }

    .action-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80%;
      height: 20%;
      position: relative;

      .login-button {
        width: 80%;
        height: 45px;
        border-radius: 10px;
        margin-bottom: 20px;
        margin: 0 auto;
      }

      .google-login-button {
        width: 80%;

        background-color: #ebedf1;

        .MuiButton-startIcon {
          width: 10%;
          margin: 0 10px;
          padding: 0;
        }
      }
      .google-icon {
        width: 80%;
        height: auto;
      }

      .btn-progress {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -12px;
        margin-left: -12px;
      }
    }

    .signup-container {
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      color: gray;

      &:hover {
        color: #f7ac04;
        text-decoration: underline;
        cursor: pointer;
      }

      .icon {
        font-size: 16px;
        padding-left: 5px;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .login-page {
    .login-content-container {
      width: 90%;

      .title {
        font-size: 28px;
      }
    }
  }
}

@media (min-width: 461px) and (max-width: 770px) {
  .login-page {
    .login-content-container {
      width: 70%;
    }
  }
}

@media (min-width: 771px) {
  .login-page {
    .login-content-container {
      width: 35%;

      .title {
        font-size: 33px;
      }
    }
  }
}
